var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"login ma-0 pa-4 bg-color--blue color--white",attrs:{"fluid":""}},[(_vm.actionShown === 'login')?_c('v-form',{ref:"loginForm",staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}},model:{value:(_vm.loginValid),callback:function ($$v) {_vm.loginValid=$$v},expression:"loginValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"heading heading-medium color--lightgreen"},[_vm._v(" Welcome back to Tasq. ")]),_c('p',{staticClass:"font--18"},[_vm._v(" Enter your email below and we’ll send a text with a verification code. ")]),_c('v-text-field',{staticClass:"textfield textfield-default--white",class:{
            'textfield-default--white--filled': _vm.email,
            'textfield-default--white--error': _vm.errorMessage,
          },attrs:{"outlined":"","label":"Email","color":"#fff","name":"email","type":"email","single-line":"","rules":[
            function (v) { return !!v || 'Email must not be blank'; },
            function (v) { return /.+@.+\..+/.test(v) || 'E-mail must be valid'; } ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.errorMessage)?_c('p',{staticClass:"color--deepblue font--14"},[_c('span',{staticClass:"font-bold"},[_vm._v(" Hmm, this doesn't look right. ")]),_c('br'),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_c('v-col',{staticClass:"login__bottom-col",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-default btn-default-long cmb-20",attrs:{"id":"send_code_btn","color":"#000750","dark":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.login}},[_vm._v(" Send my code ")])],1)],1)],1):_vm._e(),(_vm.actionShown === 'verify')?_c('v-form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.verify($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"heading heading-medium"},[_vm._v(" Verification code sent. ")]),_c('p',{staticClass:"font--14 cmb-20"},[_vm._v(" Check your text messages and enter the six-digit code to login. ")]),_c('div',{staticClass:"login__code-wrapper"},_vm._l((_vm.$options.CODE_LENGTH),function(i){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i - 1]),expression:"code[i - 1]"}],key:i,ref:("codeInput-" + (i - 1)),refInFor:true,staticClass:"textfield-code",class:{
              'textfield-code--filled': _vm.code[i - 1],
              'textfield-code--error': _vm.errorMessage,
            },attrs:{"color":"#fff","type":"text","maxLength":"1","size":"1","min":"0","max":"9","pattern":"[0-9]*"},domProps:{"value":(_vm.code[i - 1])},on:{"keyup":function($event){return _vm.changeInputFocus(i - 1)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.code, i - 1, $event.target.value)}}})}),0),(_vm.errorMessage)?_c('p',{staticClass:"color--deepblue font--14"},[_c('span',{staticClass:"font-bold"},[_vm._v(" Hmm, this doesn't look right. ")]),_c('br'),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('v-col',{staticClass:"login__bottom-col",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"btn-default btn-default-long",attrs:{"id":"verify_code_btn","color":"#000750","dark":"","disabled":_vm.verifyBtnDisabled || _vm.loading,"loading":_vm.loading},on:{"click":_vm.verify}},[_vm._v(" Verify ")]),_c('p',{staticClass:"font--14"},[_vm._v(" Text didn't arrive? "),_c('br'),_c('a',{staticClass:"font-bold color--deepblue text-underline",on:{"click":function($event){return _vm.login(true)}}},[_vm._v(" Send me another six-digit code. ")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }