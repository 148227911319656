<template>
  <v-container
    fluid
    class="login ma-0 pa-4 bg-color--blue color--white"
  >
    <v-form
      v-if="actionShown === 'login'"
      ref="loginForm"
      v-model="loginValid"
      class="w-100"
      @submit.prevent="login"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <p
            class="heading heading-medium color--lightgreen"
          >
            Welcome back to Tasq.
          </p>
          <p
            class="font--18"
          >
            Enter your email below and we’ll send a text with a verification code.
          </p>
          <v-text-field
            v-model="email"
            outlined
            label="Email"
            color="#fff"
            name="email"
            type="email"
            single-line
            :rules="[
              v => !!v || 'Email must not be blank',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]"
            class="textfield textfield-default--white"
            :class="{
              'textfield-default--white--filled': email,
              'textfield-default--white--error': errorMessage,
            }"
          />
          <p
            v-if="errorMessage"
            class="color--deepblue font--14"
          >
            <span class="font-bold">
              Hmm, this doesn't look right.
            </span>
            <br>
            {{ errorMessage }}
          </p>
        </v-col>
        <v-col
          cols="12"
          class="login__bottom-col"
        >
          <v-btn
            id="send_code_btn"
            color="#000750"
            dark
            :disabled="loading"
            :loading="loading"
            class="btn-default btn-default-long cmb-20"
            @click="login"
          >
            Send my code
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-form
      v-if="actionShown === 'verify'"
      class="w-100"
      @submit.prevent="verify"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <p
            class="heading heading-medium"
          >
            Verification code sent.
          </p>
          <p
            class="font--14 cmb-20"
          >
            Check your text messages and enter the six-digit code to login.
          </p>
          <div
            class="login__code-wrapper"
          >
            <input
              v-for="i in $options.CODE_LENGTH"
              :key="i"
              :ref="`codeInput-${i - 1}`"
              v-model="code[i - 1]"
              color="#fff"
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]*"
              class="textfield-code"
              :class="{
                'textfield-code--filled': code[i - 1],
                'textfield-code--error': errorMessage,
              }"
              @keyup="changeInputFocus(i - 1)"
            >
          </div>
          <p
            v-if="errorMessage"
            class="color--deepblue font--14"
          >
            <span class="font-bold">
              Hmm, this doesn't look right.
            </span>
            <br>
            {{ errorMessage }}
          </p>
        </v-col>
        <v-col
          cols="12"
          class="login__bottom-col"
        >
          <v-btn
            id="verify_code_btn"
            color="#000750"
            dark
            :disabled="verifyBtnDisabled || loading"
            :loading="loading"
            class="btn-default btn-default-long"
            @click="verify"
          >
            Verify
          </v-btn>
          <p
            class="font--14"
          >
            Text didn't arrive?
            <br>
            <a
              class="font-bold color--deepblue text-underline"
              @click="login(true)"
            >
              Send me another six-digit code.
            </a>
          </p>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
// import {
//   LOGIN,
//   VERIFY_USER,
// } from 'Store/mutation-types';
import { post } from 'axios';
import { getEnv } from 'Utils/helpers';

const Login = {
  CODE_LENGTH: 6,
  name: 'Login',
  computed: {
    verifyBtnDisabled() {
      return !this.code.every((i) => i !== '');
    },
  },
  data: () => ({
    email: '',
    loginValid: false,
    actionShown: 'login',
    code: [
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    errorMessage: '',
    loading: false,
  }),
  methods: {
    changeInputFocus(idx) {
      if (this.code[idx] === '') {
        const index = idx === 0 ? 0 : idx - 1;
        this.$refs[`codeInput-${index}`][0].focus();
        return;
      }
      if (this.code[idx] && idx === this.$options.CODE_LENGTH - 1) {
        this.$refs[`codeInput-${this.$options.CODE_LENGTH - 1}`][0].blur();
        return;
      }
      this.$nextTick(() => {
        const index = idx === this.$options.CODE_LENGTH - 1
          ? this.$options.CODE_LENGTH - 1 : idx + 1;
        this.$refs[`codeInput-${index}`][0].focus();
      });
    },
    async login(forced = false) {
      if ((this.$refs.loginForm && this.$refs.loginForm.validate()) || forced) {
        this.errorMessage = '';
        this.loading = true;
        try {
          // this.actionShown = await this.$store.dispatch(LOGIN, {
          //   email: this.email,
          // });
          const { data: { session, tokens } } = await post(`${getEnv('AUTH_ENDPOINT_URL')}/auth`, {
            type: 'login',
            email: this.email,
          });
          if (session) {
            localStorage.setItem('session', session);
            localStorage.setItem('email', this.email);
            this.loading = false;
            this.actionShown = 'verify';
            return;
          }
          if (tokens) {
            localStorage.removeItem('IdToken');
            localStorage.removeItem('RefreshToken');
            Object.keys(tokens).map((token) => localStorage.setItem(token, tokens[token]));
            this.loading = false;
          }
        } catch (e) {
          if (e.message === 'User does not exist.') {
            e.message = 'Please check your email.';
          }
          this.errorMessage = e.message;
          this.loading = false;
        }
      }
    },
    async verify() {
      this.loading = true;
      this.errorMessage = '';
      try {
        const { data: { tokens } } = await post(`${getEnv('AUTH_ENDPOINT_URL')}/auth`, {
          type: 'respond',
          session: localStorage.getItem('session'),
          email: localStorage.getItem('email'),
          code: this.code.join(''),
        });
        if (tokens) {
          localStorage.removeItem('IdToken');
          localStorage.removeItem('RefreshToken');
          Object.keys(tokens).map((token) => localStorage.setItem(token, tokens[token]));
          this.loading = false;
        }
        this.$router.push({ name: 'Home' });
        this.loading = false;
      } catch (e) {
        e.message = 'Let’s try that again — check your code and make sure you enter the correct numbers.';
        this.errorMessage = e.message;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.$eventBus.$emit('loading', false);
  },
};

export default Login;
</script>

<style lang="stylus" scoped>
.login
  min-height 100vh
  &__bottom-col
    text-align center
    align-self flex-end
    margin-bottom 80px
  &__code
    &-wrapper
      @media (min-width 992px)
        text-align center

/ >>> .error--text
  color #fc0 !important
  caret-color #fc0 !important
</style>
